<template>
    <client-page>

        <sub-visual-business-domestic />

        <section class="section">
            <v-container>

                <page-title-operate />

                <tab-business-domestic />

                
                <business-item imageGrid="4" tit="강동아이플러스키움센터" file="gd" url="https://blog.naver.com/kdiplus21">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            주요사업 : 놀이, 휴식 중심(저학년)과 학습 및 체험활동 프로그램(고학년) 등
                        </li>
                        <li class="dot-text">
                            소재지 : 서울시 강동구 올림픽로 752, k타워 3층
                        </li>
                        <li class="dot-text">
                            규모 : 346.64㎡
                        </li>
                        <li class="dot-text">
                            설립일 : 2021.9.1
                        </li>
                        <li class="dot-text">
                            이용정원 : 40명
                        </li>
                        <li class="dot-text">
                            시설장 : 조은애
                        </li>
                        <li class="dot-text">
                            종사자 : 5명
                        </li>
                        <li class="dot-text">
                            주요사업 : 방과 후 돌봄제공(급,간식제공), 주말돌봄, 특화프로그램 운영, 마을단위 돌봄 네트워크 운영, 돌봄 사각지대 발굴 등
                        </li>
                    </ul>
                </business-item> 

                <business-item imageGrid="4" tit="광진구9호점우리동네키움센터" file="gjg" url="https://naver.me/FHuRvFSz">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 아동돌봄시설
                        </li>
                        <li class="dot-text">
                            소재지 : 서울시 광진구 천호대로 716, 별관 1호
                        </li>
                        <li class="dot-text">
                            규모 : 70.56㎡
                        </li>
                        <li class="dot-text">
                            설립일 : 2021. 7. 1
                        </li>
                        <li class="dot-text">
                            이용정원 : 16명
                        </li>
                        <li class="dot-text">
                            시설장 : 백진우
                        </li>
                        <li class="dot-text">
                            종사자 : 2명
                        </li>
                        <li class="dot-text">
                            주요사업 : 초등 아동 돌봄, 주제별 활동 및 예체능 특별 프로그램, PBL기반 아동주도 프로그램, 지역자원 연계 활동 등
                        </li>
                    </ul>
                </business-item>

                <business-item imageGrid="4" tit="자곡다함께키움센터(강남8호점 우리동네키움센터)" file="gn" url="https://blog.naver.com/jagokkium">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 방과 후 초등돌봄시설
                        </li>
                        <li class="dot-text">
                            소재지 : 서울특별시 강남구 자곡로 100, 1층
                        </li>
                        <li class="dot-text">
                            규모 : 77.4㎡
                        </li>
                        <li class="dot-text">
                            설립일 : 2022.4.22
                        </li>
                        <li class="dot-text">
                            이용정원 : 15명
                        </li>
                        <li class="dot-text">
                            시설장 : 황윤영
                        </li>
                        <li class="dot-text">
                            종사자 : 2명
                        </li>
                        <li class="dot-text">
                            주요사업 : 급간식 및 놀이학습, 문화체험활동과 지역연계 특화프로그램 운영
                        </li>
                    </ul>
                </business-item> 

                <business-item imageGrid="4" tit="서초포레스타2단지어린이집" file="scf" url="https://youtube.com/@user-hj4gs3nq8h">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 보육시설
                        </li>
                        <li class="dot-text">
                            소재지 : 서울특별시 서초구 헌릉로 8길 45, 211동 1층
                        </li>
                        <li class="dot-text">
                            규모 : 어린이집 583㎡
                        </li>
                        <li class="dot-text">
                            설립일 : 2015.9.15
                        </li>
                        <li class="dot-text">
                            이용정원 : 105명
                        </li>
                        <li class="dot-text">
                            시설장 : 김지연
                        </li>
                        <li class="dot-text">
                            종사자 : 16명
                        </li>
                        <li class="dot-text">
                            주요사업 : 놀이중심 누리과정 표준보육과정 및 생태친화보육, 언어통합활동, 지역연계사업, 특별활동 및 특성화 교육사업 등
                        </li>
                    </ul>
                </business-item> 

                <business-item imageGrid="4" tit="마포대흥어린이집" file="mp" url="https://cafe.naver.com/kid2">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 국공립 보육시설
                        </li>
                        <li class="dot-text">
                            소재지 : 서울 마포구 백범로79
                        </li>
                        <li class="dot-text">
                            규모 : 451 ㎡
                        </li>
                        <li class="dot-text">
                            설립일 : 1998.3.1
                        </li>
                        <li class="dot-text">
                            이용정원 : 74명
                        </li>
                        <li class="dot-text">
                            시설장 : 박미정
                        </li>
                        <li class="dot-text">
                            종사자 : 14명
                        </li>
                        <li class="dot-text">
                            주요사업 : 지역사회 연계활동, 놀이줌심교육, 자녀양육부담 완화 등
                        </li>
                    </ul>
                </business-item> 

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBusinessDomestic from "@/components/client/sub/sub-visual-business-domestic.vue";
import TabBusinessDomestic from '@/components/client/sub/tab-business-domestic.vue';
import PageTitleOperate from '@/components/client/sub/page-title-operate.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisualBusinessDomestic,
        TabBusinessDomestic,
        PageTitleOperate,
        BusinessItem,
    },
};
</script>