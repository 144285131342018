var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-business-domestic'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title-operate'), _c('tab-business-domestic'), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "강동아이플러스키움센터",
      "file": "gd",
      "url": "https://blog.naver.com/kdiplus21"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 놀이, 휴식 중심(저학년)과 학습 및 체험활동 프로그램(고학년) 등 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 서울시 강동구 올림픽로 752, k타워 3층 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 규모 : 346.64㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 설립일 : 2021.9.1 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 40명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 조은애 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 5명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 방과 후 돌봄제공(급,간식제공), 주말돌봄, 특화프로그램 운영, 마을단위 돌봄 네트워크 운영, 돌봄 사각지대 발굴 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "광진구9호점우리동네키움센터",
      "file": "gjg",
      "url": "https://naver.me/FHuRvFSz"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 아동돌봄시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 서울시 광진구 천호대로 716, 별관 1호 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 규모 : 70.56㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 설립일 : 2021. 7. 1 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 16명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 백진우 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 2명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 초등 아동 돌봄, 주제별 활동 및 예체능 특별 프로그램, PBL기반 아동주도 프로그램, 지역자원 연계 활동 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "자곡다함께키움센터(강남8호점 우리동네키움센터)",
      "file": "gn",
      "url": "https://blog.naver.com/jagokkium"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 방과 후 초등돌봄시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 서울특별시 강남구 자곡로 100, 1층 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 규모 : 77.4㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 설립일 : 2022.4.22 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 15명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 황윤영 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 2명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 급간식 및 놀이학습, 문화체험활동과 지역연계 특화프로그램 운영 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "서초포레스타2단지어린이집",
      "file": "scf",
      "url": "https://youtube.com/@user-hj4gs3nq8h"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 보육시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 서울특별시 서초구 헌릉로 8길 45, 211동 1층 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 규모 : 어린이집 583㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 설립일 : 2015.9.15 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 105명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 김지연 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 16명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 놀이중심 누리과정 표준보육과정 및 생태친화보육, 언어통합활동, 지역연계사업, 특별활동 및 특성화 교육사업 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "마포대흥어린이집",
      "file": "mp",
      "url": "https://cafe.naver.com/kid2"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 국공립 보육시설 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 서울 마포구 백범로79 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 규모 : 451 ㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 설립일 : 1998.3.1 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 이용정원 : 74명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 박미정 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 14명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 지역사회 연계활동, 놀이줌심교육, 자녀양육부담 완화 등 ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }